$ = jQuery;
/*Functions*/

function fixed_header() {
    if ($(this).scrollTop() > 1) {
        $('header').addClass("fixed");
    } else {
        $('header').removeClass("fixed");
    }
}

/**
 *
 */
function search_result_topics(element) {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var search_word = url.searchParams.get("s");
    $('<form action="'+ element.attr('href') +'" method="post"><input type="hidden" name="search_word" value="' + search_word + '"></form>').appendTo('body').submit();
}

function markButtonPosition(index) {
    var currentMark = $(".mark").eq(index);
    $([document.documentElement, document.body]).animate({
        scrollTop: currentMark.offset().top - 100
    }, 1000);
}

function scrollToMark() {
    if(mark[0]) {
        markButtonPosition(0);
    }
    $('footer').append('<button class="scroll_to_mark i-chevron-down" data-mark="0"></button>');
    markButtonPosition(0);
}

/**
 * Google Rating
 */
function starsHover() {
    var ratingInner = $(".stars_rating.g_rating .g_rating_inner"),
        ratingInnerWidth = ratingInner.innerWidth(),
        ratingBlock = $(".stars_rating.g_rating"),
        id = $(".g_rating_block .count_number").attr('data-id');
    ratingCount = $(".g_rating_block .count_number span");
    ratingBlock.on("click", function() {
        if (!getCookie('added_rating' + id) && !$(this).data("clicked")) {
            ratingCount.text(parseInt(ratingCount.text()) + 1);
            console.log(ratingCount.text());
            var data = {
                'action': 'add_rating_count',
                'count': ratingCount.html(),
                'id': id
            };
            $.ajax({
                url: '/wp-admin/admin-ajax.php',
                data:data,
                type:'POST',
                success:function(data){
                    if( data ) {
                        ratingCount.text(data);
                    }
                }
            });
        }
        setCookie('added_rating' + id,'true',10);
        $(this).data("clicked", !0)
    });
    if(!getCookie('added_rating' + id) && !$(this).data("clicked")){
        ratingBlock.on("mousemove", function(e) {
            if (!$(this).data("clicked")) {
                var ratingInnerWidth = e.clientX - $(this).offset().left;
                ratingInner.css("width", ratingInnerWidth)
            }
        });
        ratingBlock.on("mouseleave", function(e) {
            ratingInner.css("width", ratingInnerWidth)
        })
    }
}
/*Scroll top*/
function showScrollTop() {
    if ($(this).scrollTop() > 100) {
        $("#to_top").addClass('active');
    }
    else {
        $("#to_top").removeClass('active');
    }
}

$(document).ready(function () {
    "use strict";
    var siteBody = $("body");
    /*Init*/
    siteBody.addClass(currentBrowser()).addClass(detectmob());
    showScrollTop();
    $('.search_result_items a').on('click',function (e) {
        e.preventDefault();
        search_result_topics($(this));
    });

    $(window).on('load', function () {
        if(siteBody.hasClass('page-template-tpl-sub-category') || siteBody.hasClass('page-template-tpl-sub-sub-category')) {
            scrollToMark();
        }
        $('.scroll_to_mark').on('click',function () {
            if($(this).attr('data-mark') < $('.mark').size() - 1) {
                $(this).attr('data-mark',parseInt($(this).attr('data-mark')) + 1);
                markButtonPosition($(this).attr('data-mark'));
            }
        });
    });
    $(window).scroll(function () {
        showScrollTop();
    });
    if($(".g_rating")[0]){
        starsHover();
    }
    $('.search_btn, .search_box .close').on('click',function () {
        $('.search_box').toggleClass('active');
    });

    $(window).scroll(function () {
        fixed_header()
    });

    fixed_header();

    /*Scroll top Click*/
    $("#to_top").on("click","a", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('html, body').animate({scrollTop: top}, 1000);
    });
    /*Menu*/
    $("#menuOpen button.hamburger").on('click',function (e) {
        $('#mainMenu').toggleClass("opened");
        $(this).parent().toggleClass("opened");
        $(this).toggleClass('is-active');
    });
    $('#mainMenu li.menu-item-has-children').append('<span class="subMenuButton i-angle-down"></span>');
    $('.subMenuButton').on('click',function () {
        var t = $(this);
        if(t.hasClass('open')) {
            t.removeClass('open').prev().slideUp(300);
        } else {
            t.removeClass('open').prev().slideDown(300);
            t.addClass('open');
        }
    });
    //WPCF7
    $(this).on('click', '.wpcf7-not-valid-tip', function () {
        $(this).prev().trigger('focus');
        $(this).fadeOut(500, function () {
            $(this).remove();
        });
    });
    //Privacy
    if(!getCookie('privacy')){
        var privacy = $('#privacy');
        privacy.show();
    }
    $('#privacy .privacy_close').on('click',function () {
        $('#privacy').addClass('hide');
        setCookie('privacy','true',1);
    });
});